import { Flex, Text, Image, DrawerOverlay, DrawerContent, DrawerBody, Drawer, useDisclosure } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'
import React from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { Button, RouteLabel, SearchInput, TransportIcon } from '@ubirider/pick-component-library'
import { RouteCard } from '../RouteCard'
import { StopCard } from '../StopCard'

import arrowBack from '../../../../assets/arrow_left_simple.svg'
import search from '../../../../assets/search.svg'
import stopIcon from '../../../../assets/stop_sign.svg'

function CardsDrawer({
  network,
  selectedNetwork,
  selectedRoute,
  selectedStop,
  selectedPattern,
  stopSequence,
  searchValue,
  updateSearchValue,
  hoveredRoute,
  handleHoverRoute,
  hoveredStop,
  handleHoverStop,
  showMobileSearchBar,
  updateMobileSearchBar,
  updateSelectedRoute,
  updateSelectedStop,
  isOpenSchedules,
  onOpenSchedules,
}) {
  const { t } = useTranslation()

  const { control } = useFormContext()

  const { isOpen: isOpenDrawer, onOpen: onOpenDrawer, onClose: onCloseDrawer } = useDisclosure()

  return (
    <>
      {!showMobileSearchBar && selectedNetwork && !isOpenDrawer && !isOpenSchedules && (
        <>
          <Flex display={{ sm: 'flex', lg: 'none' }} position='absolute' top='94px' left='16px' zIndex='5'>
            <Button
              onClick={() => updateSearchValue('')}
              type={'neutralLight'}
              style={'outline'}
              leftIconProps={{ src: arrowBack }}
              css={{ height: '48px !important', width: '48px !important', padding: '16px !important' }}
            />
          </Flex>

          <Flex display={{ sm: 'flex', lg: 'none' }} position='absolute' top='94px' right='16px' zIndex='5'>
            <Button
              onClick={() => updateMobileSearchBar(true)}
              type={'neutralLight'}
              style={'outline'}
              leftIconProps={{ src: search }}
              css={{ height: '48px !important', width: '48px !important', padding: '16px !important' }}
            />
          </Flex>
        </>
      )}
      {showMobileSearchBar && selectedNetwork && !isOpenDrawer && !isOpenSchedules && (
        <Flex display={{ sm: 'flex', lg: 'none' }} position='absolute' top='94px' zIndex='5' w='100%' padding='0 16px'>
          <Controller
            name='search'
            control={control}
            render={({ field: { value, onChange } }) => (
              <SearchInput
                value={searchValue}
                onChange={e => {
                  onChange(e)
                  updateSearchValue(e)
                }}
                placeholder={t('network_schedule.search_route')}
                css={{ textAlign: 'left' }}
                background={'white'}
              />
            )}
          />
        </Flex>
      )}
      {selectedNetwork && !selectedStop && (
        <Flex
          display={isOpenDrawer ? { sm: 'none', lg: 'none' } : { sm: 'flex', lg: 'none' }}
          position='fixed'
          bottom='0'
          direction='column'
          bg='white'
          boxShadow='0px 2px 20px 1px rgba(0, 0, 0, 0.10)'
          h='120px'
          w='100%'
          gap='8px'
          align='center'
          justify='flex-start'
          padding='8px'
          as='button'
          onClick={onOpenDrawer}
        >
          <Flex bg='#CED0DA' borderRadius='10px' justify='center' align='center' w='24px' h='4px' zIndex='5' />
          <Flex
            direction='column'
            gap='8px'
            padding='0px 16px 0px 16px'
            align='flex-start'
            justify='flex-start'
            w='100%'
          >
            <Flex gap='8px' align='center'>
              <Text fontSize='14px' fontWeight='700' color='#1D2939'>
                {t('network_schedule.route')}
              </Text>
              <RouteLabel
                shortName={selectedNetwork?.route.shortName}
                color={selectedNetwork?.route.color}
                textColor={selectedNetwork?.route.textColor}
              />
            </Flex>
            <Flex gap='8px' align='center'>
              <TransportIcon type={3} />
              <Text textAlign='left' fontSize='18px' fontWeight='700' color='#1D2939'>
                {selectedNetwork?.route.name.replace(
                  /([A-ZÁÉÍÓÚÃÕÂÊÔÇÀÜÈÌÒÙ]+)/g,
                  match => match.charAt(0) + match.slice(1).toLowerCase()
                )}
              </Text>
            </Flex>
          </Flex>
        </Flex>
      )}
      {selectedNetwork && selectedStop && (
        <Flex
          display={isOpenDrawer ? { sm: 'none', lg: 'none' } : { sm: 'flex', lg: 'none' }}
          position='fixed'
          bottom='0'
          direction='column'
          bg='white'
          boxShadow='0px 2px 20px 1px rgba(0, 0, 0, 0.10)'
          h='120px'
          w='100%'
          align='center'
          justify='flex-start'
          gap='8px'
          padding='8px'
          as='button'
          onClick={onOpenDrawer}
        >
          <Flex bg='#CED0DA' borderRadius='10px' justify='center' align='center' w='24px' h='4px' zIndex='2' />
          <Flex
            direction='column'
            gap='8px'
            padding='0px 16px 0px 16px'
            align='flex-start'
            justify='flex-start'
            w='100%'
          >
            <Text fontSize='14px' fontWeight='700' color='#1D2939'>
              {t('network_schedule.stop')}
            </Text>
            <Flex gap='8px' align='center'>
              <Image src={stopIcon} />
              <Text textAlign='left' fontSize='18px' fontWeight='700' color='#1D2939'>
                {network.stops
                  .find(stop => stop.id === selectedStop)
                  .name.replace(/([A-ZÁÉÍÓÚÃÕÂÊÔÇÀÜÈÌÒÙ]+)/g, match => match.charAt(0) + match.slice(1).toLowerCase())}
              </Text>
            </Flex>
          </Flex>
        </Flex>
      )}
      <Drawer placement='bottom' onClose={onCloseDrawer} isOpen={isOpenDrawer}>
        <DrawerOverlay />
        <DrawerContent display={{ sm: 'flex', lg: 'none' }} mt='70px' h='calc(100% - 70px)'>
          <DrawerBody>
            {/* Selected Route Card */}
            {!selectedStop && selectedRoute && selectedNetwork?.route.id === selectedRoute && (
              <RouteCard
                focus={true}
                network={network}
                selectedNetwork={selectedNetwork}
                stops={selectedNetwork?.stops}
                alerts={selectedNetwork?.alerts}
                selectedRoute={selectedRoute}
                selectedPattern={selectedPattern}
                selectedStop={selectedStop}
                stopSequence={stopSequence}
                handleHoverRoute={handleHoverRoute}
                hoveredRoute={hoveredRoute}
                hoveredStop={hoveredStop}
                handleHoverStop={handleHoverStop}
                setSearchValue={updateSearchValue}
                openSchedules={onOpenSchedules}
                closeDrawer={onCloseDrawer}
              />
            )}

            {/* Selected Stop Card */}
            {selectedStop && searchValue !== '' && (
              <StopCard
                network={network}
                selectedNetwork={selectedNetwork}
                selectedStop={selectedStop}
                selectedPattern={selectedPattern}
                handleHoverRoute={handleHoverRoute}
                setSearchValue={updateSearchValue}
                updateSelectedRoute={updateSelectedRoute}
                updateSelectedStop={updateSelectedStop}
                openSchedules={onOpenSchedules}
                closeDrawer={onCloseDrawer}
              />
            )}
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  )
}

export default CardsDrawer
