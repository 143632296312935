import { Flex, Image } from '@chakra-ui/react'
import React from 'react'

import TrevoStopChangeDesktop from '../../../../../../assets/stop_changes_trevo_desktop.png'
import TrevoStopChangeMobile from '../../../../../../assets/stop_changes_trevo_mobile.png'

export function TrevoStopChanges() {
  return (
    <Flex h={{ sm: '716px' }} w='100%' align='flex-start' justify='center' position='relative' overflow='hidden'>
        <Flex display={{ sm: 'none', md: 'none', lg: 'flex', xl: 'flex' }} w={'100%'} justify="center" align="center">
          <Image src={TrevoStopChangeDesktop} objectFit='cover' objectPosition={'left center!important'} w={'100%'} />
        </Flex>

        <Flex display={{ sm: 'flex', md: 'flex', lg: 'none', xl: 'none' }} w={'100%'} justify="center" align="center">
          <Image src={TrevoStopChangeMobile} h={'716px'} w={'100%'} objectFit={'cover !important'} objectPosition={'center!important'} />
        </Flex>
    </Flex>
  )
}
