import { Flex, Text, Image, Spinner } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'
import React, { useEffect, useRef, useState } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { Button, SearchInput } from '@ubirider/pick-component-library'
import Cookies from 'universal-cookie/es6'
import { SearchResultsCard } from './components/SearchResultsCard'
import { RouteCard } from '../RouteCard'
import { StopCard } from '../StopCard'
import { PickPromoBanner } from './components/PickPromoBanner'

import searchResultsIcon from '../../../../assets/search_circle.svg'
import arrowBack from '../../../../assets/arrow_left.svg'
import { useNavigate } from 'react-router-dom'
import config from '../../../../config/configLoader'

function CardsList({
  network,
  selectedNetwork,
  selectedRoute,
  selectedStop,
  selectedPattern,
  stopSequence,
  searchValue,
  updateSearchValue,
  filteredRoutes,
  filteredStops,
  hoveredRoute,
  handleHoverRoute,
  hoveredStop,
  handleHoverStop,
  updateSelectedRoute,
  updateSelectedStop,
  updateSelectedRouteThenStop,
  alerts,
  loading,
  onOpenSchedules,
}) {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const cookies = new Cookies()
  const pickCookie = cookies.get('PickPref')
  const cookiePerms = cookies.get('CookieConsent')

  const { control } = useFormContext()

  const [showPickPromo, togglePickPromo] = useState(
    pickCookie === undefined || cookiePerms !== 'all' ? true : pickCookie
  )
  const [isScrolled, setIsScrolled] = useState(false)

  const flexRef = useRef()

  useEffect(() => {
    if (flexRef.current) {
      flexRef.current.scrollTo({
        top: 0,
        behavior: 'smooth',
      })
    }
  }, [selectedNetwork?.route, selectedPattern])

  const handlePromoChange = value => {
    if (cookiePerms === 'all') {
      togglePickPromo(value)
      cookies.set('PickPref', value)
    }
  }

  const handleScroll = event => {
    const element = event.target
    if (element.scrollTop > 0) {
      setIsScrolled(true)
    } else {
      setIsScrolled(false)
    }
  }

  return (
    <Flex
      display={selectedNetwork ? { sm: 'none', lg: 'flex' } : 'flex'}
      h='calc(100vh - 70px)'
      direction='column'
      bg='white'
      boxShadow='0px 2px 20px 1px rgba(0, 0, 0, 0.1)'
      overflow='hidden'
      w='100%'
      minW={{ lg: '465px' }}
      flex={{ sm: '1', lg: '2.5' }}
    >
      <Flex
        w='100%'
        padding={{ sm: '16px 16px 0px 16px', lg: '16px 32px 0px 32px' }}
        borderBottom='16px solid white'
        boxShadow={isScrolled ? '0px 2px 20px 1px rgba(0, 0, 0, 0.10)' : null}
        zIndex='1300'
        backgroundColor='white'
      >
        <Flex w='100%' direction='column' gap='16px'>
          {selectedNetwork && (selectedRoute || selectedStop) && window.history.length > 0 && (
            <Button
              onClick={() => navigate(-1)}
              type={'neutralLight'}
              style={'typo'}
              size={'small'}
              label={t('common.back')}
              leftIconProps={{ src: arrowBack }}
              css={{ width: '120px', justifyContent: 'flex-start' }}
            />
          )}

          {((!selectedNetwork && (!selectedRoute || !selectedStop)) || window.history.length === 0) && (
            <Flex display={{ sm: 'none', lg: 'flex' }} h='36px'></Flex>
          )}

          <Controller
            name='search'
            control={control}
            render={({ field: { value, onChange } }) => (
              <SearchInput
                value={searchValue}
                onChange={e => {
                  onChange(e)
                  updateSearchValue(e)
                }}
                placeholder={t('network_schedule.search_route')}
                css={{ textAlign: 'left' }}
                background={'white'}
              />
            )}
          />
        </Flex>
      </Flex>

      <Flex
        ref={flexRef}
        className='routes'
        direction='column'
        gap='8px'
        overflow='auto'
        padding={{ sm: '24px 16px', lg: '16px 32px' }}
        onScroll={handleScroll}
        css={{
          lg: {
            '&::-webkit-scrollbar': {
              width: '8px', // Set the width of the scrollbar
            },
            '&::-webkit-scrollbar-thumb': {
              borderRadius: '8px',
              backgroundColor: '#98A2B3', // Set the color of the scrollbar thumb
            },
            '&::-webkit-scrollbar-track': {
              borderRadius: '8px',
              backgroundColor: 'none', // Set the color of the scrollbar track
            },
          },
        }}
      >
        {searchValue === '' && config.sections.pick && (
          <PickPromoBanner
            showPickPromo={showPickPromo}
            cookiePerms={cookiePerms}
            handlePromoChange={handlePromoChange}
          />
        )}

        {searchValue !== '' && !selectedNetwork && (filteredRoutes?.length > 0 || filteredStops?.length > 0) && (
          <SearchResultsCard
            network={network}
            filteredRoutes={filteredRoutes}
            filteredStops={filteredStops}
            updateSelectedStop={updateSelectedRouteThenStop}
            handleHoverRoute={handleHoverRoute}
            handleHoverStop={handleHoverStop}
          />
        )}

        {/* All Route Cards when no Route is selected and nothing in search bar */}
        {searchValue === '' &&
          !window.location.pathname.includes('/stop') &&
          !selectedNetwork &&
          network?.routes?.map((route, i) => (
            <RouteCard
              key={i}
              focus={false}
              network={route}
              stops={network?.stops}
              alerts={alerts}
              selectedPattern={selectedPattern}
              stopSequence={stopSequence}
              handleHoverStop={handleHoverStop}
              handleHoverRoute={handleHoverRoute}
              hoveredRoute={hoveredRoute}
              hoveredStop={hoveredStop}
              searchValue={searchValue}
              setSearchValue={updateSearchValue}
              openSchedules={onOpenSchedules}
            />
          ))}

        {/* Selected Route Card */}
        {!selectedStop && selectedRoute && selectedNetwork?.route.id === selectedRoute && (
          <RouteCard
            focus={true}
            network={network}
            selectedNetwork={selectedNetwork}
            stops={selectedNetwork?.stops}
            alerts={selectedNetwork?.alerts}
            selectedRoute={selectedRoute}
            selectedPattern={selectedPattern}
            selectedStop={selectedStop}
            stopSequence={stopSequence}
            hoveredRoute={hoveredRoute}
            handleHoverRoute={handleHoverRoute}
            hoveredStop={hoveredStop}
            handleHoverStop={handleHoverStop}
            setSearchValue={updateSearchValue}
            openSchedules={onOpenSchedules}
          />
        )}

        {/* Selected Stop Card */}
        {selectedStop && searchValue !== '' && (
          <StopCard
            network={network}
            selectedNetwork={selectedNetwork}
            selectedStop={selectedStop}
            selectedPattern={selectedPattern}
            handleHoverRoute={handleHoverRoute}
            updateSelectedRoute={updateSelectedRoute}
            updateSelectedStop={updateSelectedStop}
            setSearchValue={updateSearchValue}
            openSchedules={onOpenSchedules}
            loading={loading}
          />
        )}

        {/* No search results */}
        {searchValue !== '' &&
          !selectedStop &&
          !selectedRoute &&
          filteredRoutes?.length === 0 &&
          filteredStops?.length === 0 && (
            <Flex direction='column' padding='16px' gap='16px' justify='center' align='center'>
              <Image src={searchResultsIcon} />
              <Flex direction='column' gap='8px' align='center'>
                <Text fontSize='16px' fontWeight='700' color='#667085' textAlign='center'>
                  {t('network_schedule.no_results_found_title')}
                </Text>
                <Text fontSize='14px' fontWeight='600' color='#667085' textAlign='center'>
                  {t('network_schedule.no_results_found_subtitle')}
                </Text>
              </Flex>
            </Flex>
          )}
      </Flex>
    </Flex>
  )
}

export default CardsList
