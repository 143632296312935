import {Flex, Link, Text} from "@chakra-ui/react";
import {Trans, useTranslation} from "react-i18next";
import React, {useEffect} from "react";
import {CookieTable} from "./components/CookieTable";
import {BrowserTable} from "./components/BrowserTable";
import config from "../../config/configLoader";

function CookiePolicy({openCookiePopup}) {
    const {t} = useTranslation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <Flex direction="column" flex="1" bg="#F9F9FB">
            <Flex
                direction="column"
                gap={{sm: "48px", md: "48px", lg: "80px", xl: "80px"}}
                padding={{sm: "32px 24px", md: "32px 24px", lg: "100px 130px", xl: "100px 130px"}}
                width="100%"
            >
                <Flex direction="column" gap="24px">
                    <Text fontSize={{sm: "24px", md: "24px", lg: "36px", xl: "36px"}} fontWeight="700" color="#002239" align="center" justify="center">
                        {t("cookie_policy.cookie_policy_description")}
                    </Text>
                    <Text fontSize={{sm: "14px", md: "14px", lg: "16px", xl: "16px"}} color="#1D2939" align="center" justify="center">
                        {t("cookie_policy.cookie_policy_description_1")}
                    </Text>
                </Flex>

                <Flex
                    w="100%"
                    direction="column"
                    gap="32px"
                    padding={{sm: "20px", md: "20px", lg: "40px", xl: "40px"}}
                    align="flex-start"
                    justify="center"
                    bg="white"
                    border="1px solid #D3D3D3"
                    borderRadius="8px"
                    flexWrap="wrap"
                >
                    <Flex direction="column" gap="16px">
                        <Text fontSize={{sm: "14px", md: "14px", lg: "16px", xl: "16px"}} fontWeight="700" color="#475467">{t("cookie_policy.cookie_policy_description_2")}</Text>
                        <Text fontSize={{sm: "14px", md: "14px", lg: "16px", xl: "16px"}} color="#475467">{t("cookie_policy.cookie_policy_description_3")}</Text>
                        <Text fontSize={{sm: "14px", md: "14px", lg: "16px", xl: "16px"}} color="#475467">
                            <Trans i18nKey="cookie_policy.cookie_policy_description_4">
                                Find out more about cookies at: <Link className="chakra-custom-link" sx={{ textDecoration: 'underline', fontWeight: '600'}} href="https://allaboutcookies.org/" target="_blank">allaboutcookies.org</Link>.
                            </Trans>
                        </Text>
                    </Flex>
                    <Flex direction="column" gap="16px">
                        <Text fontSize={{sm: "14px", md: "14px", lg: "16px", xl: "16px"}} fontWeight="700" color="#475467">{t("cookie_policy.cookie_policy_description_5")}</Text>
                        <Text fontSize={{sm: "14px", md: "14px", lg: "16px", xl: "16px"}} color="#475467">{t("cookie_policy.cookie_policy_description_6")}</Text>
                        <Text fontSize={{sm: "14px", md: "14px", lg: "16px", xl: "16px"}} color="#475467">{t("cookie_policy.cookie_policy_description_7")}</Text>
                    </Flex>
                    <Flex direction="column" gap="16px">
                        <Text fontSize={{sm: "14px", md: "14px", lg: "16px", xl: "16px"}} fontWeight="700" color="#475467">{t("cookie_policy.cookie_policy_description_8")}</Text>
                        <Text fontSize={{sm: "14px", md: "14px", lg: "16px", xl: "16px"}} color="#475467">{t("cookie_policy.cookie_policy_description_9")}</Text>
                    </Flex>
                    <Flex direction="column" gap="16px">
                        <Text fontSize={{sm: "14px", md: "14px", lg: "16px", xl: "16px"}} fontWeight="700" color="#475467">{t("cookie_policy.cookie_policy_description_10")}</Text>
                        <Text fontSize={{sm: "14px", md: "14px", lg: "16px", xl: "16px"}} color="#475467">
                            <Trans i18nKey="cookie_policy.cookie_policy_description_11"/>
                        </Text>
                        <Text fontSize={{sm: "14px", md: "14px", lg: "16px", xl: "16px"}} color="#475467">
                            <Trans i18nKey="cookie_policy.cookie_policy_description_12"/>
                        </Text>
                        <Text fontSize={{sm: "14px", md: "14px", lg: "16px", xl: "16px"}} color="#475467">
                            <Trans i18nKey="cookie_policy.cookie_policy_description_13"/>
                        </Text>
                        <Text fontSize={{sm: "14px", md: "14px", lg: "16px", xl: "16px"}} color="#475467">
                            <Trans i18nKey="cookie_policy.cookie_policy_description_14"/>
                        </Text>
                    </Flex>
                    <Flex direction="column" gap="16px">
                        <Text fontSize={{sm: "14px", md: "14px", lg: "16px", xl: "16px"}} fontWeight="700" color="#475467">{t("cookie_policy.cookie_policy_description_15")}</Text>
                        <Text fontSize={{sm: "14px", md: "14px", lg: "16px", xl: "16px"}} color="#475467">
                            <Trans i18nKey="cookie_policy.cookie_policy_description_16"/>
                        </Text>
                        <Text fontSize={{sm: "14px", md: "14px", lg: "16px", xl: "16px"}} color="#475467">
                            <Trans i18nKey="cookie_policy.cookie_policy_description_17"/>
                        </Text>
                    </Flex>
                    <Flex direction="column" gap="16px">
                        <Text fontSize={{sm: "14px", md: "14px", lg: "16px", xl: "16px"}} color="#475467">{t("cookie_policy.cookie_policy_description_18")}</Text>
                        <CookieTable />
                    </Flex>
                    <Text fontSize={{sm: "14px", md: "14px", lg: "16px", xl: "16px"}} fontWeight="700" color="#475467">
                        <Trans i18nKey="cookie_policy.cookie_policy_description_19">
                            To withdraw your consent to the use of cookies on our website click <span onClick={openCookiePopup} style={{ cursor: 'pointer', textDecoration: 'underline' }}>here</span>.
                        </Trans>
                    </Text>
                    <Flex direction="column" gap="16px">
                        <Text fontSize={{sm: "14px", md: "14px", lg: "16px", xl: "16px"}} fontWeight="700" color="#475467">{t("cookie_policy.cookie_policy_description_20")}</Text>
                        <Text fontSize={{sm: "14px", md: "14px", lg: "16px", xl: "16px"}} color="#475467">{t("cookie_policy.cookie_policy_description_21")}</Text>
                        <Text fontSize={{sm: "14px", md: "14px", lg: "16px", xl: "16px"}} color="#475467">{t("cookie_policy.cookie_policy_description_22")}</Text>
                    </Flex>
                    <BrowserTable />
                    <Text fontSize={{sm: "14px", md: "14px", lg: "16px", xl: "16px"}} color="#475467">
                        <Trans i18nKey="cookie_policy.cookie_policy_description_23">
                            If you use other browsers, please check: <Link className="chakra-custom-link" sx={{ textDecoration: 'underline', fontWeight: '600'}} href="https://www.civicuk.com/cookie-control" target="_blank">https://www.civicuk.com/cookie-control</Link>
                        </Trans>
                    </Text>
                    <Text fontSize={{sm: "14px", md: "14px", lg: "16px", xl: "16px"}} fontWeight="700" color="#475467">{t("cookie_policy.cookie_policy_description_24")}</Text>
                    <Text fontSize={{sm: "14px", md: "14px", lg: "16px", xl: "16px"}} color="#475467">{t("cookie_policy.cookie_policy_description_25")}</Text>

                    {(config.operator === 'proximo' || config.operator === 'MOBIAZORES') &&
                      <Flex gap={'8px'}>
                          <Text fontSize={{ sm: '13px', md: '13px', lg: '14px', xl: '14px' }} fontWeight='600'>
                              {t('common.last_update')}
                          </Text>
                          <Text fontSize={{ sm: '13px', md: '13px', lg: '14px', xl: '14px' }}>{t('cookie_policy.last_updated')}</Text>
                      </Flex>
                    }
                </Flex>
            </Flex>
        </Flex>
    );
}

export default CookiePolicy;
